const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_RELEVANCE = 'relevance';
const SORT_ENTRY_NUMBER = 'entryNumber';

const VIPO_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_ENTRY_NUMBER,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_ENTRY_NUMBER]: {
        label: 'Entry No',
        value: ['vipo_work_number.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    if ($vm.sort === SORT_ENTRY_NUMBER) {
      return ['vipo_work_number.keyword'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_ENTRY_NUMBER;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.vipo_entry_urn[0]),
  queries: {
    index_terms: '',
    work_labels: '',
    version_kinds: '',
  },
  lookups: {
    index_terms: [],
    work_labels: [],
    version_kinds: [],
  },
  filters: {
    index_terms: null,
    work_labels: null,
    version_kinds: null,
  },
  facets: [
    {
      label: 'Index',
      value: 'index_terms',
      toolTipText: "This search function works only for the edition. To search for the normalised index terms in the introduction, please use your browser's search function. You can find the full list of index terms in the Table of Contents, under the tab 'Personen- und Ortsregister'.",
      placeholder: 'Type to filter',
    },
    {
      label: 'Work',
      value: 'work_labels',
      toolTipText: 'Filter by work',
      placeholder: 'Type to filter',
    },
    {
      label: 'Edition',
      value: 'version_kinds',
      flatten: true,
      multiple: true,
      toolTipText: 'Filter by edition',
      placeholder: 'Type to filter',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default VIPO_SCHEMA;
