const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_TITLE = 'title';
const SORT_RELEVANCE = 'relevance';

const SGGO_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_TITLE,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_TITLE]: {
        label: 'Entry',
        value: ['sggo_work_label.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    if ($vm.sort === SORT_TITLE) {
      return ['sggo_work_label.keyword'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_TITLE;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.sggo_entry_urn[0]),
  queries: {
    index_grammarians: '',
    index_grammarian_works: '',
    index_provenances: '',
    index_dates: '',
    index_sources: '',
    index_subjects: '',
    index_jacoby_onlines: '',
    index_lggas: '',
    index_languages: '',
  },
  lookups: {
    index_grammarians: [],
    index_grammarian_works: [],
    index_provenances: [],
    index_dates: [],
    index_sources: [],
    index_subjects: [],
    index_jacoby_onlines: [],
    index_lggas: [],
    index_languages: [],
  },
  filters: {
    index_grammarians: null,
    index_grammarian_works: null,
    index_provenances: null,
    index_dates: null,
    index_sources: null,
    index_subjects: null,
    index_jacoby_onlines: null,
    index_lggas: null,
    index_languages: null,
  },
  facets: [
    {
      label: 'Grammarian',
      value: 'index_grammarians',
      toolTipText: 'Filter by grammarian',
      placeholder: 'Type to filter',
    },
    {
      label: 'Grammarian work',
      value: 'index_grammarian_works',
      toolTipText: 'Filter by grammarian work',
      placeholder: 'Type to filter',
    },
    {
      label: 'Provenance',
      value: 'index_provenances',
      toolTipText: 'Filter by provenance',
      placeholder: 'Type to filter',
    },
    {
      label: 'Date',
      value: 'index_dates',
      toolTipText: 'Filter by date',
      placeholder: 'Type to filter',
    },
    {
      label: 'Source',
      value: 'index_sources',
      toolTipText: 'Filter by source',
      placeholder: 'Type to filter',
    },
    {
      label: 'Subject',
      value: 'index_subjects',
      toolTipText: 'Filter by subject',
      placeholder: 'Type to filter',
    },
    {
      label: 'Jacoby Online',
      value: 'index_jacoby_onlines',
      toolTipText: 'Filter by Jacoby Online',
      placeholder: 'Type to filter',
    },
    {
      label: 'LGGA',
      value: 'index_lggas',
      toolTipText: 'Filter by LGGA',
      placeholder: 'Type to filter',
    },
    {
      label: 'Language',
      value: 'index_languages',
      toolTipText: 'Filter by language',
      placeholder: 'Type to filter',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default SGGO_SCHEMA;
