<template>
  <div class="tg-landing">
    <div class="row mb-4">
      <SearchBar
        class="col-sm-12"
        :value="queries.keyword"
        :reveal-help="revealHelp"
        :show-form="showForm"
        @toggle-form="toggleForm"
        @toggle-reveal-help="toggleRevealHelp"
        @on-user-search="handleUserSearch"
      />
    </div>
    <div class="row mt-4 mb-4">
      <div class="col-sm-12 d-flex">
        <div
          v-show="queries.keyword && !loading"
          class="btn btn-sm btn-primary mr-2"
          @click="resetQuery('keyword')"
        >
          Search: "{{ queries.keyword }}" <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
        <!-- TODO: Render facet details based on available facets -->
        <template
          v-for="field in facetFields"
        >
          <DateRangeDetail
            v-if="field.kind == 'dateRange'"
            :key="field.value"
            :min-value="queries[field.min.value]"
            :max-value="queries[field.max.value]"
            :field="field"
            @reset="resetQuery"
          />
          <FacetDetail
            v-else
            :key="field.value"
            :label="field.label"
            :value="field.flatten ? flattenValues(filters[`${field.value}`]) : filters[`${field.value}`]"
            :shown="field.multiple ? filters[field.value] && filters[field.value].length : filters[field.value]"
            :filter-name="field.value"
            @reset="resetFilter"
          />
        </template>
        <div
          v-if="activeCriteriaKeys().length > 0 && !loading"
          class="btn btn-sm btn-danger ml-auto"
          @click="resetAll"
        >
          Clear all <span
            class="badge"
          >
            <span class="fa fa-times" />
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-4 justify-content-center">
      <div
        v-if="showForm"
        class="tg-facets form col-lg-3 col-md-9 mb-5"
      >
        <div
          v-if="lensesEntries.length > 1"
        >
          <LensDropDown
            :selected-lens="lens"
            :lenses="lensesEntries"
            @update-lens="(lens_) => lens = lens_"
          />
        </div>
        <div class="card">
          <div class="card-header">
            Filter
          </div>
          <div class="card-body">
            <template v-for="field in facetFields">
              <DateRangeSelect
                v-if="field.kind == 'dateRange'"
                :key="field.value"
                :min-value="queries[field.min.value]"
                :max-value="queries[field.max.value]"
                :field="field"
                @update-field="(field, value) => updateQuery(field, value)"
              />
              <FacetSelect
                v-else
                :key="field.value"
                v-model="filters[`${field.value}`]"
                :label="field.label"
                :options="filteredOptions(lookups[`${field.value}`], filters[`${field.value}`])"
                :tooltip-text="field.toolTipText"
                :multiple="field.multiple || false"
                :placeholder="field.placeholder"
                :operator="field.operator ? operators[`${field.value}`] : null"
                @handle-search="(query, loadFn) => searchFilter(`${field.value}`, query, loadFn)"
                @force-options-reset="resetQuery(`${field.value}`)"
                @update-operator="(value) => updateOperator(`${field.value}`, value)"
              />
            </template>
          </div>
        </div>
      </div>
      <page-loader v-if="loading" />
      <div
        v-else-if="total > 0"
        :key="lens"
        :class="{'col-md-9': showForm, 'col-md-12': !showForm}"
        class="tg-facets results"
      >
        <payload-cleaned-notice v-if="wasCleaned" />
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                Found <HumanizedNumber :value="total" /> {{ hitsPluralLabel }}
              </div>
              <!-- TODO: Specify sorting labels / fields as top-level params in the
              collection metadata -->
              <div class="col-sm-6 text-right">
                sorted by:
                <span
                  v-for="[fieldKey, data], fIdx) in activeSortFields"
                  :key="fieldKey"
                >
                  <a
                    href="#"
                    :class="sort === fieldKey ? 'font-weight-bold' : ''"
                    @click.prevent="updateSort(fieldKey)"
                  >
                    <span>{{ data.label }}</span>
                  </a>
                  <span v-if="fIdx != activeSortFields.length - 1">
                    /
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="list-group list-group-flush">
            <template v-if="lensKind == 'fine'">
              <component
                :is="fineLensComponent"
                v-for="result in results"
                :key="result.urn"
                :queries="queries"
                :result="result"
                :links_new_tab="getLinksNewTabs"
              />
            </template>
            <template v-else-if="lensKind == 'work'">
              <component
                :is="workLensComponent"
                v-for="result in results"
                :key="result.urn"
                :result="result"
                :selected-work="selectedWork"
                :total="getWorkFieldPassageCount(result) || null"
                :labelLink="true"
                @passage-results-handler="showPassageResults"
              />
            </template>
            <template v-else-if="lensKind == 'version'">
              <component
                :is="versionLensComponent"
                v-for="result in results"
                :key="result.urn"
                :result="result"
                :selected-work="selectedVersion"
                :total="getVersionFieldPassageCount(result) || null"
                :labelLink="true"
                @passage-results-handler="showPassageResults"
              />
            </template>
          </div>
          <div class="card-footer">
            <nav
              :aria-label="`Paginate ${hitsPluralLabel}`"
              class="justify-content-between d-flex"
            >
              <ul class="pagination pagination-sm mb-0">
                <li class="d-flex flex-column justify-content-center">
                  Page {{ currentPage }} of {{ totalPages }}
                </li>
              </ul>
              <div class="btn-group">
                <button
                  :disabled="!hasPreviousPage()"
                  class="btn btn-primary ml-0"
                  title="First page"
                  @click="firstPage"
                >
                  &lt;&lt;
                </button>
                <button
                  :disabled="!hasPreviousPage()"
                  class="btn btn-primary ml-0"
                  title="Previous page"
                  @click="() => hasPreviousPage() && previousPage()"
                >
                  Previous
                </button>
                <button
                  :disabled="!hasNextPage()"
                  class="btn btn-primary ml-0"
                  title="Next page"
                  @click="nextPage"
                >
                  Next
                </button>
                <button
                  :disabled="!hasNextPage()"
                  class="btn btn-primary ml-0"
                  title="Last page"
                  @click="lastPage"
                >
                  &gt;&gt;
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div
        v-else-if="!loading"
        :class="{'col-md-9': showForm, 'col-md-12': !showForm}"
      >
        No results found. Please try again.
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import api from '@/js/api';
import Skeleton2NavigationMixin from '@/js/mixins/Skeleton2NavigationMixin.vue';
import { syncSessionStorage } from '@/js/storage';
import { b64DecodeUnicode, b64EncodeUnicode } from '@/js/strUtils';
import { RenderOptions } from '@/js/rendering';
import LensDropDown from './LensDropDown.vue';
import DateRangeDetail from './DateRangeDetail.vue';
import DateRangeSelect from './DateRangeSelect.vue';
import EntrySearchResultDetail from './EntrySearchResultDetail.vue';
import FacetDetail from './FacetDetail.vue';
import FacetSelect from './FacetSelect.vue';
import HumanizedNumber from './HumanizedNumber.vue';
import PayloadCleanedNotice from './PayloadCleanedNotice.vue';
import PassageSearchResultDetail from './PassageSearchResultDetail.vue';
import SearchBar from './SearchBar.vue';
// TODO: Import using factory pattern
import SEGEntrySearchResultDetail from './SEGEntrySearchResultDetail.vue';
import { cleanPayload } from './utils';
import DSS_SCHEMA from './schemas/dss';
import CSO_SCHEMA from './schemas/cso';
import CTS_COLLECTION_SCHEMA from './schemas/cts_collection';
import MULTI_TG_COLLECTION_SCHEMA from './schemas/multi_tg_cts_collection';
import JO_SCHEMA from './schemas/jo';
import SEG_SCHEMA from './schemas/seg';
import PEZ_SCHEMA from './schemas/pez';
import VIPO_SCHEMA from './schemas/vipo';
import SGGO_SCHEMA from './schemas/sggo';
import DLCC_SCHEMA from './schemas/dlcc';

import SQSToggleMixin from './mixins/SQSToggleMixin.vue';
import LibLynxCountSearchMixin from "@/js/mixins/LibLynxCountSearchMixin.vue";


const debounce = require('lodash.debounce');

const DEBOUNCE_TIMEOUT = 300;

const SORT_RELEVANCE = 'relevance';

const CATO_TG_URNS = new Set([
  'urn:cts:arabicLit:0279Baladhuri',
  'urn:cts:arabicLit:0284Yacqubi',
  'urn:cts:arabicLit:0290FaqihHamadhani',
  'urn:cts:arabicLit:0300Khurradadhbih',
  'urn:cts:arabicLit:0300Rusta',
  'urn:cts:arabicLit:0310Tabari',
  'urn:cts:arabicLit:0337QudamaJacfar',
  'urn:cts:arabicLit:0345Mascudi',
  'urn:cts:arabicLit:0346IshaqIstakhri',
  'urn:cts:arabicLit:0367QasimHawqal',
  'urn:cts:arabicLit:0370CaribSacidQurtubi',
  'urn:cts:arabicLit:0381Muqaddasi'
]);

const CAT2_TG_URNS = new Set([
  'urn:cts:arabicLit:0000CAT2',
  'urn:cts:arabicLit:0827RabbanTabari',
  'urn:cts:arabicLit:0601Maimonides',
  'urn:cts:arabicLit:0962alThaalibi',
  'urn:cts:arabicLit:0961Aleppo',
  'urn:cts:arabicLit:0822Suhrawardi',
  'urn:cts:arabicLit:0995MuhammadHabib',
  'urn:cts:arabicLit:0808Mudejar',
  'urn:cts:arabicLit:0880WahhabSharani',
  'urn:cts:arabicLit:0988AbuMashar',
  'urn:cts:arabicLit:0824AhmadWallali',
  'urn:cts:arabicLit:0844MuhammadSayyari',
  'urn:cts:arabicLit:0913SahibAbbad',
  'urn:cts:arabicLit:0997SharifRadi',
  'urn:cts:arabicLit:0936Timbuktawi',
  'urn:cts:arabicLit:0919Averroes',
  'urn:cts:arabicLit:0968DawudQaysari',
  'urn:cts:arabicLit:0983GhulamKhalil',
  'urn:cts:arabicLit:0907HafizBasir',
  'urn:cts:arabicLit:0896HasanUjaymi',
  'urn:cts:arabicLit:0974Ibadi',
  'urn:cts:arabicLit:0882Jazzar',
  'urn:cts:arabicLit:0823Shamma',
  'urn:cts:arabicLit:0826Barrajan',
  'urn:cts:arabicLit:0857Bukhtishu',
  'urn:cts:arabicLit:0946Tumlus',
  'urn:cts:arabicLit:0966KeyScience',
  'urn:cts:arabicLit:0933KhalidYazid',
  'urn:cts:arabicLit:0818Khunaji',
  'urn:cts:arabicLit:0970MahammadDukkali',
  'urn:cts:arabicLit:0841MuhammadSiddiqi',
  'urn:cts:arabicLit:0843MuhammadWulati',
  'urn:cts:arabicLit:0189MuhammadShaybani',
  'urn:cts:arabicLit:0839NajibSamarqandi',
  'urn:cts:arabicLit:0980NajmTufi',
  'urn:cts:arabicLit:0927NurSabuni',
  'urn:cts:arabicLit:0934pdRhazes',
  'urn:cts:arabicLit:0924SharafMasudi',
  'urn:cts:arabicLit:0954Sacromonte',
  'urn:cts:arabicLit:0991Tusi',
  'urn:cts:arabicLit:0838YusufHasan',
]);

function truthy(value) {
  return value && (Number.isInteger(value) || value.length > 0);
}


function parseComponentState(stringifiedState) {
  return JSON.parse(b64DecodeUnicode(stringifiedState));
}

function stringifyComponentState(component, keys) {
  return b64EncodeUnicode(JSON.stringify(Object.fromEntries(keys.map(k => [k, component[k]]))));
}

function loadStateFromHistory() {
  const search = new URLSearchParams(window.location.search);
  const facetState = search.get('facets');
  return facetState || undefined;
}

function loadStateFromSession(sessionKey) {
  const facetState = syncSessionStorage.getItem(sessionKey);
  return facetState || undefined;
}

function getData(sessionKey, defaults, reset = false) {
  const savedState = loadStateFromHistory() || loadStateFromSession(sessionKey);
  const fallback = {
    ...defaults,
  };
  let payload = {};
  if (!reset) {
    payload = savedState ? parseComponentState(savedState) : {};
  }
  const payloadRequiresValidation = Object.keys(payload).length !== 0;
  if (payloadRequiresValidation) {
    const { cleanedPayload, wasCleaned } = cleanPayload(payload, defaults);
    return {
      data: cleanedPayload,
      wasCleaned,
    };
  }
  return {
    wasCleaned: false,
    data: fallback,
  };
}

function getSchema(collectionUrn, textGroupUrn) {
  if (collectionUrn.endsWith(':seg')) {
    return SEG_SCHEMA;
  }
  if (collectionUrn.endsWith(':cso')) {
    return CSO_SCHEMA;
  }
  if (collectionUrn.endsWith(':dss')) {
    return DSS_SCHEMA;
  }
  if (collectionUrn.endsWith(':jo')) {
    return JO_SCHEMA;
  }
  if (collectionUrn.endsWith(':pez')) {
    return PEZ_SCHEMA;
  }
  // FIXME: Remove hardcoded CATO check in favor
  // of a CATO collection
  if (collectionUrn.endsWith(':se') && (CATO_TG_URNS.has(textGroupUrn) || CAT2_TG_URNS.has(textGroupUrn))) {
    return MULTI_TG_COLLECTION_SCHEMA;
  }
  if (collectionUrn.endsWith(':se') && textGroupUrn.endsWith(":vipo")) {
    return VIPO_SCHEMA;
  }
  if (collectionUrn.endsWith(':sggo')) {
    return SGGO_SCHEMA;
  }
  if (collectionUrn.endsWith(':dlcc')) {
    return DLCC_SCHEMA;
  }
  if (collectionUrn.endsWith(':se')) {
    return CTS_COLLECTION_SCHEMA;
  }
  return undefined;
}

function getInitialData(collectionUrn, $vm) {
  const textGroupUrn = $vm.$route.params.urn;
  const schema = getSchema(collectionUrn, textGroupUrn);
  const { sortFields } = schema;
  const defaults = {
    // TODO: Fetch data defaults (lens, showForm, etc) using ATLAS or Manifest data
    collectionUrn,
    lens: schema.defaultLens,
    total: 0,
    offset: 0,
    size: 50,
    userHasSorted: false,
    sort: sortFields.default,
    sortFields,
    loading: true,
    facetLoading: null,
    wasCleaned: false,
    isInitialSearch: null,
    showForm: true, // initial state for faceting panel
    results: [],
    operators: {
      index_terms: 'AND',
    },
    // TODO: Build up non-keyword queries, lookups, filters using ATLAS or ES data
    queries: schema.queries,
    lookups: schema.lookups,
    filters: schema.filters,
    meta: {},
    revealHelp: true,
    coarsePassagesLookup: null,
  };
  // TODO: This keeps us from getting into an inconsistent state
  const bail = $vm.$route.query.initial || $vm.$route.query.work_title;
  let data = {};
  let wasCleaned = false;
  if (bail) {
    data = {
      ...defaults,
    };
    data.wasCleaned = false;
  } else {
    ({ data, wasCleaned } = getData(collectionUrn, defaults));
    data.wasCleaned = wasCleaned;
  }

  if ($vm.$route.query.initial) {
    ({ data, wasCleaned } = getData(collectionUrn, defaults, true));
    const initialPayload = JSON.parse(b64DecodeUnicode($vm.$route.query.initial));
    const providedLens = initialPayload.lens;
    if (providedLens) {
      delete initialPayload.lens;
      data.lens = providedLens;
    }
    Object.assign(data.filters, initialPayload);
    data.showForm = true;
  }
  data.schema = schema;
  return data;
}

export default {
  // FIXME: Refactor with MetadataCollectionWorkList.vue
  name: 'CTSCollectionList',
  components: {
    SearchBar,
    // TODO: Load these detail components via a factory pattern
    PassageSearchResultDetail,
    EntrySearchResultDetail,
    SEGEntrySearchResultDetail,
    DateRangeDetail,
    DateRangeSelect,
    FacetDetail,
    FacetSelect,
    HumanizedNumber,
    LensDropDown,
    'v-select': VueSelect,
    PayloadCleanedNotice,
  },
  mixins: [Skeleton2NavigationMixin, SQSToggleMixin, LibLynxCountSearchMixin],
  props: ['textGroup'],
  data() {
    // FIXME: Remove fallback and fail gracefully
    // TODO: Find a better pattern for this anyways
    // const collectionUrn = RenderOptions.fromTextGroup(this.textGroup).get('metadataCollectionUrn') || 'urn:cite2:scholarlyEditions:metadata_collection.atlas_v1:cso';
    const collectionUrn = 'urn:cite2:scholarlyEditions:metadata_collection.atlas_v1:se';
    const $vm = this;
    return getInitialData(collectionUrn, $vm);
  },
  computed: {
    selectedWork() {
      return this.filters.work_labels ? this.filters.work_labels : undefined;
    },
    selectedVersion() {
      return this.filters.version_labels ? this.filters.version_labels : undefined;
    },
    urn() {
      // FIXME: Do we want to mint new URNs?
      return this.$route.params.urn;
    },
    currentPage() {
      return Math.round(this.offset / this.size) + 1;
    },
    totalPages() {
      const pagesRequired = this.total / this.size;
      return Math.ceil(pagesRequired);
    },
    lensKind() {
      return this.lenses[this.lens].kind;
    },
    hitsPluralLabel() {
      const lenseData = this.lenses[this.lens];
      return this.total === 1 ? lenseData.label : lenseData.pluralLabel;
    },
    facetFields() {
      return this.schema.facets;
    },
    lenses() {
      return this.schema.lenses;
    },
    lensesEntries() {
      return Object.entries(this.lenses);
    },
    activeSortFields() {
      const isKeywordSearch = this.queries.keyword;
      // TODO: Improve this interface
      const fields = Object.entries(this.sortFields.fields).filter(obj => (isKeywordSearch ? true : !obj[1].keywordOnly));
      return fields.filter(obj => (this.lenses[this.lens].sortFields.has(obj[0])));
    },
    fineLensComponent() {
      return PassageSearchResultDetail;
    },
    workLensComponent() {
      return this.schema.workLensComponent;
    },
    versionLensComponent() {
      return this.schema.versionLensComponent;
    },
    getLinksNewTabs() {
      return this.textGroup.metadata.manifest.linksNewTab || false;
    },
  },
  watch: {
    '$route.query.facets': function () {
      // NOTE: This allows the component data to update
      // when the user navigates backwards / forwards in the browser
      const { collectionUrn } = this;
      const data = getInitialData(collectionUrn, this);
      Object.keys(data).forEach((key) => { this[key] = data[key]; });
    },
    lens(newValue) {
      // FIXME: Make this less buggy
      // if (!this.userHasSorted && newValue === 'work') {
      //   this.sort = 'canonical';
      // }
      this.search();
    },
    sort() {
      this.search();
    },
    offset() {
      this.loading = true;
      this.search();
    },
    size() {
      this.search();
    },
    filters: {
      deep: true,
      handler() {
        // A changed filter should trigger a new search count in Liblynx
        this.liblynxResetCounted()
        this.firstPage();
        this.search();
      },
    },
    operators: {
      deep: true,
      handler() {
        this.loading = true;
        this.search();
      },
    },
    queries: {
      deep: true,
      handler(value) {
        // A changed search query should trigger a new search count in Liblynx
        this.liblynxResetCounted()
        Object.keys(value).forEach((k) => {
          if (this.filters[k] === false) {
            return;
          }
          if (value[k] && value[k] !== this.filters[k]) {
            this.lookup(k, value[k]);
          }
        });
      },
    },
    'queries.keyword': {
      deep: true,
      handler(newValue, oldValue) {
        if (this.schema.keywordWatcherFunc) {
          const $vm = this;
          return this.schema.keywordWatcherFunc($vm, newValue, oldValue);
        }
        return null;
      },
    },
  },
  created() {
    this.search();
  },
  methods: {
    capFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    firstPage() {
      this.offset = 0;
      this.loading = true;
    },
    lastPage() {
      this.offset = (this.totalPages - 1) * this.size;
    },
    nextPage() {
      this.offset += this.size;
    },
    hasNextPage() {
      return this.offset + this.size < this.total;
    },
    previousPage() {
      this.offset -= this.size;
    },
    hasPreviousPage() {
      return this.offset > 0;
    },
    getSortParams() {
      const $vm = this;
      return this.schema.sortParamsFunc ? this.schema.sortParamsFunc($vm) : [];
    },
    // TODO: Pre-search to trigger loading?
    search: debounce(function f() {
      const params = {
        offset: this.offset,
        size: this.size,
        sort: this.getSortParams(),
        ...this.searchParams(true),
      };
      const facetState = stringifyComponentState(this, [
        'lens',
        'filters',
        'queries',
        'operators',
        'offset',
        'size',
        'sort',
        'showForm',
      ]);

      this.syncRouterState(facetState);

      const storageKey = this.collectionUrn;
      syncSessionStorage.setItem(storageKey, facetState);

      api.searchFacetsATLAS(params, ({ hits, total, grouped_passage_counts }) => {
        this.total = total;
        this.results = hits;
        this.coarsePassagesLookup = grouped_passage_counts;
        this.initialLookup();
        if (this.facetLoading) {
          this.facetLoading(false);
          this.facetLoading = null;
        }

        this.loading = false;

        this.updateSearchState();

        if (this.showForm) {
          // FIXME: Can we do this in a less brute-force fashion?
          this.$el.scrollIntoView();
        }
      });
    }, DEBOUNCE_TIMEOUT),
    lookup: debounce(function f(facet, include) {
      const params = {
        include,
        facet,
        ...this.searchParams(true),
      };
      api.facetLookupATLAS(params, ({ aggregations, meta }) => {
        this.lookups[facet] = aggregations[facet].buckets.map(b => b.key);
        for (const [metaKey, metaValue] of Object.entries(meta)) {
          this.meta[metaKey] = metaValue ? metaValue.buckets.map(b => b.key) : [];
        }

        if (this.facetLoading) {
          this.facetLoading(false);
          this.facetLoading = null;
        }
      });
    }, DEBOUNCE_TIMEOUT),
    initialLookup() {
      const facets = Object.keys(this.filters);
      const params = {
        include: '', facet: facets.join(','), ...this.searchParams(true),
      };
      api.facetLookupATLAS(params, ({ aggregations, meta }) => {
        facets.forEach((facet) => {
          const aggregation = aggregations[facet];
          this.lookups[facet] = aggregation ? aggregation.buckets.map(b => b.key) : [];
        });

        for (const [metaKey, metaValue] of Object.entries(meta)) {
          this.meta[metaKey] = metaValue ? metaValue.buckets.map(b => b.key) : [];
        }
        this.libLynxCountSearch('publication')
      });
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    toggleRevealHelp() {
      this.revealHelp = !this.revealHelp;
    },
    toggleLens(value) {
      this.loading = true;
      this.lens = value;
    },
    resetFilter(key) {
      this.queries[key] = '';
      this.filters[key] = null;
      this.firstPage();
    },
    resetQuery(key) {
      this.queries[key] = '';
      this.firstPage();
      this.search();
    },
    activeFilterKeys() {
      return Object.keys(this.filters).filter(k => truthy(this.filters[k]));
    },
    activeQueryKeys() {
      return Object.keys(this.queries).filter(k => truthy(this.queries[k]));
    },
    activeCriteriaKeys() {
      return this.activeQueryKeys().concat(this.activeFilterKeys());
    },
    resetAll() {
      this.activeFilterKeys().forEach((k) => {
        this.filters[k] = null;
      });
      this.activeQueryKeys().forEach((k) => {
        this.queries[k] = '';
      });
      this.firstPage();
      this.search();
    },
    searchFilter(key, value, loadFn) {
      if (value) {
        this.facetLoading = loadFn;
        this.facetLoading(true);
      }
      this.queries[key] = value;
    },
    updateOperator(field, value) {
      this.operators[field] = value;
    },
    updateQuery(field, value) {
      this.queries[field] = value;
      this.search();
    },
    flattenValues(values) {
      return values && values.length ? values.map(c => `"${c}"`).join(' + ') : '';
    },
    getCoarseFieldPassageCount(result) {
      const key = this.schema.coarseResultKeyFunc(result);
      const values = this.coarsePassagesLookup[key];
      return values;
    },
    getVersionFieldPassageCount(result) {
      const key = this.schema.versionResultKeyFunc(result);
      return this.coarsePassagesLookup[key];
    },
    getWorkFieldPassageCount(result) {
      const key = this.schema.workResultKeyFunc(result);
      return this.coarsePassagesLookup[key];
    },
    filteredOptions(options, value) {
      const valueIsh = typeof (value) === 'number' ? value.toString() : value;
      return valueIsh ? options.filter(o => valueIsh.indexOf(o) < 0) : options;
    },
    updateSort(value) {
      // TODO: Encapsulate this further
      this.sort = value;
      if (this.queries.keyword && value !== SORT_RELEVANCE) {
        this.userHasSorted = true;
      }
    },
    updateSearchState() {
      /*
      Ensures that `wasCleaned` is unset once the user
      has searched or filtered the data further.
      */
      if (!this.wasCleaned) {
        // no-op if the payload was not cleaned
        return;
      }
      if (this.isInitialSearch === false) {
        // no-op if the user has made a subsequent search
        return;
      }
      if (this.isInitialSearch === true) {
        // the user has just made a new search
        this.isInitialSearch = false;
        this.wasCleaned = false;
        return;
      }
      // the initial search has just happened
      this.isInitialSearch = true;
    },
    showPassageResults(fieldValues) {
      this.toggleLens('fine');
      Object.entries(fieldValues).forEach(([key, value]) => {
        this.filters[key] = value;
      });
    },
    handleUserSearch: debounce(function f(value) {
      this.queries.keyword = value;
      this.firstPage();
      this.search();
    }, DEBOUNCE_TIMEOUT)
  },
};
</script>

<style>
.v-select .vs__dropdown-menu {
  width: auto;
  max-width: 200%;
  min-width: 100%;
}
</style>
