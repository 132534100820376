const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_TITLE = 'title';
const SORT_RELEVANCE = 'relevance';

const DLCC_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_TITLE,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_TITLE]: {
        label: 'Entry',
        value: ['dlcc_work_label.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_TITLE) {
      return ['dlcc_work_label.keyword'];
    } if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_TITLE;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.dlcc_entry_urn[0]),
  queries: {
    entry_names: '',
    text_group_labels: '',
    work_labels: '',
  },
  lookups: {
    entry_names: [],
    text_group_labels: [],
    work_labels: [],
  },
  filters: {
    entry_names: null,
    text_group_labels: null,
    work_labels: null,
  },
  facets: [
    {
      label: 'Text Group',
      value: 'text_group_labels',
      toolTipText: 'Filter by text group',
      placeholder: '',
    },
    {
      label: 'Work',
      value: 'work_labels',
      toolTipText: 'Filter by work',
      placeholder: '',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default DLCC_SCHEMA;
