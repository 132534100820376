<template>
  <div class="form-group">
    <div
      v-tooltip="tooltipText"
      class="float-right"
    >
      <i class="fas fa-info icon-small" />
    </div>
    <label>{{ label }}</label>
    <v-select
      ref="selectComponent"
      :value="value"
      :options="options"
      :multiple="multiple"
      :placeholder="placeholder"
      :filter-by="filterBy"
      @input="emitToParent"
      @search="(query, loadFn) => search(query, loadFn)"
      @option:selecting="(selectedOption) => trackSelection(selectedOption) "
      @search:blur="shouldResetOptions"
    >
      <template
        slot="option"
        slot-scope="option"
      >
        <span v-html="option.label" />
      </template>
      <template
        slot="selected-option"
        slot-scope="option"
      >
        <span v-html="option.label" />
      </template>

      <template #list-footer>
      <li v-show="hasMore" class="show-more">
        More options available. Please, type to filter.
      </li>
    </template>

    </v-select>
    <OperatorToggle
      v-if="(operator && !hideOperatorToggle)"
      :value="operator"
      :label="label"
      @input="((value) => $emit('update-operator', value))"
    />
  </div>
</template>
<script>
import foldcase from '@ar-nelson/foldcase';
import VueSelect from 'vue-select';
import OperatorToggle from './OperatorToggle.vue';

export default {
  components: { OperatorToggle, 'v-select': VueSelect },
  props: {
    label: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    value: [String, Array, Number],
    options: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    tooltipText: String,
    multiple: {
      type: Boolean,
      default: false,
    },
    operator: {
      type: String,
      required: false,
    },
    hideOperatorToggle: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Type to filter...',
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
  },
  /*
  NOTE: [improve-usability]
  Most of the implementation of this component works around a reactivity issue
  with the `v-select` component.

  When a user types into the input, we dispatch a query to ElasticSearch.
  If the user clicks a result and makes a selection, everything works as expected.

  However, if the user does not click a result (because no results are found, or because they
  don't find a suitable result), the `options` passed to `v-select` are a result of the last
  ES query.

  By hooking into the `@search:blur` event handler and tracking the state of a user's interaction
  with `v-select`, we are able to detect when `options` will be in a "bad" state and force a reset.
  */
  data() {
    return {
      // NOTE: See "[improve-usability]" above
      lastSelected: null,
      lastValue: null,
      working: false,
      optionsExist: false,
      optionsHaveChanged: false,
    };
  },
  computed: {
    searchExactlyMatchesOption() {
      // NOTE: See "[improve-usability]" above
      const arrayType = Object.getPrototypeOf([]).constructor.name.toLowerCase();
      const lastValueArray = Object.getPrototypeOf(this.lastValue).constructor.name.toLowerCase() === arrayType ? this.lastValue : [this.lastValue];
      return this.options.filter(value => lastValueArray.includes(value)).length > 0;
    },
    isResettable() {
      // NOTE: See "[improve-usability]" above
      if (this.working) {
        // We're waiting on the ES query to complete
        return false;
      }
      if (!this.optionsExist) {
        // We did not have options in the "empty" state, so we
        // don't need to query again
        return false;
      }
      if (this.lastSelected) {
        // The user has made a selection
        return false;
      }
      if (!this.optionsHaveChanged) {
        // No need to reset if the options have not changed
        return false;
      }
      if (!this.lastValue) {
        // The last search was empty
        return false;
      }
      if (this.searchExactlyMatchesOption) {
        // The last search value matches an option,
        // so we have a valid selection
        return false;
      }
      return true;
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(newVal, oldVal) {
      // NOTE: See "[improve-usability]" above
        this.working = false;
        if (!this.optionsExist && newVal.length > 0) {
          this.optionsExist = true;
        }
        const prevVal = oldVal || [];
        this.optionsHaveChanged = prevVal.length > 0 && newVal.length !== prevVal.length;
      },
    },
  },
  methods: {
    noMarksNormalized(value) {
      const nfdValue = value.normalize('NFD');
      const noMarksValue = nfdValue.replace(/\p{M}/gu, '');
      const nkfcValue = noMarksValue.normalize('NFKC');
      return foldcase(nkfcValue);
    },
    filterBy(option, label, search) {
      const foldedLabel = this.noMarksNormalized(label);
      const foldedSearch = this.noMarksNormalized(search);
      return (foldedLabel || '').toLowerCase().indexOf(foldedSearch.toLowerCase()) > -1;
    },
    emitToParent(value) {
      // NOTE: For whatever reason, I couldn't find a way
      // to pass from @input up to the parent without
      // an instance method
      // TODO: do we need to fold this value or not?
      // we are folding server side.
      this.lastValue = value;
      this.$emit('input', value);
    },
    search(query, loadFn) {
      // TODO: do we need to fold this value or not?
      // we are folding server side.
      this.lastValue = query;
      this.$emit('handle-search', query, loadFn);
    },
    trackSelection(selectedOption) {
      // NOTE: See "[improve-usability]" above
      this.working = true;
      this.lastSelected = selectedOption;
    },
    shouldResetOptions() {
      // NOTE: See "[improve-usability]" above
      if (this.isResettable) {
        this.$emit('force-options-reset');
        if (this.multiple) {
          // NOTE: `v-input.search` doesn't get cleared on blur when `multiple = true`
          // (https://vue-select.org/api/props.html#clearsearchonblur),
          // so we manually do it
          this.$refs.selectComponent.search = '';
        }
      }
    },
  },
};
</script>

<style scoped>
    .show-more {
      margin-top: 10px;
      background-color: #eee;
      font-style: italic;
      font-size: 0.95rem;
      padding: 0.3rem 1.1rem;
    }
</style>
