import { render, staticRenderFns } from "./CTSCollectionList.vue?vue&type=template&id=5783c6b2&"
import script from "./CTSCollectionList.vue?vue&type=script&lang=js&"
export * from "./CTSCollectionList.vue?vue&type=script&lang=js&"
import style0 from "./CTSCollectionList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "CTSCollectionList.vue"
export default component.exports